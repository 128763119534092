export default function useHasAccess() {
  const { userRole } = useSessionInfo();
  return {
    junior: hasAccess(userRole.value, UserRole.Junior),
    senior: hasAccess(userRole.value, UserRole.Senior),
    moderator: hasAccess(userRole.value, UserRole.Moderator),
    editor: hasAccess(userRole.value, UserRole.Editor),
    administrator: hasAccess(userRole.value, UserRole.Administrator)
  };
}
